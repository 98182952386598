<template>
  <div class="map-card">
    <div class="map-card__container">
      <div class="map-card__nav">
        <form
          class="map-card__search"
          @submit.enter.prevent="$emit('search', term)"
        >
          <input
            v-model="term"
            class="map-card__search-input"
            type="search"
            :placeholder="$t('storeAddress')"
            @input="$emit('search', term)"
          />
          <button class="map-search-btn" type="submit"></button>
        </form>
        <button
          class="map-filter-btn"
          :class="{ 'is-active': isShowFilter }"
          @click="toggleAddressFilters"
        ></button>
      </div>

      <div
        class="map-card__addresses"
        :class="{
          'is-hidden is-hidden2': isShowFilter || isShowDetails,
          'is-show': isShowList && !isShowDetails,
        }"
      >
        <div
          v-for="shop in shopsList"
          :key="shop.id"
          class="map-card__addresses-item"
        >
          <div class="map-address" @click="showMoreInfo(shop)">
            <div class="map-address__descr">
              <div class="map-address__delivery-type">
                {{
                  shop.attributes.shop_type &&
                  shop.attributes.shop_type.data &&
                  shop.attributes.shop_type.data.attributes
                    ? shop.attributes.shop_type.data.attributes.name
                    : "No type"
                }}
              </div>
              <div class="map-address__name">
                {{ shop.attributes.name }}
              </div>
              <div class="map-address__location">
                {{ shop.attributes.address }}
              </div>
            </div>
            <time class="map-address__time">{{ getShopWorkTime(shop) }}</time>
          </div>
        </div>
      </div>
    </div>
    <div
      class="address-card"
      :class="{ 'is-active is-active2': isShowDetails && !isShowFilter }"
    >
      <button class="btn-back" @click="$emit('closeDetails', false)">
        {{ $t("back") }}
      </button>
      <div v-if="selectedShop" class="address-card__descr">
        <div class="address-card__delivery-type">
          {{
            selectedShop.attributes.shop_type.data.attributes
              ? selectedShop.attributes.shop_type.data.attributes.name
              : "No type"
          }}
        </div>
        <div class="address-card__name">{{ selectedShop.attributes.name }}</div>
        <div class="address-card__location">
          {{ selectedShop.attributes.address }}
        </div>
        <time class="address-card__time"
          ><span>{{ $t("workingMode") }}</span
          >{{ getShopWorkTime(selectedShop) }}</time
        >
      </div>
      <div
        v-if="selectedShop && selectedShop.attributes.shop_features"
        class="address-categories"
      >
        <span
          v-for="feature in selectedShop.attributes.shop_features.data"
          :key="feature.id"
          class="coffee-category"
        >
          <img :src="getImage(feature)" alt="" />
          {{ feature.attributes.name }}</span
        >
      </div>
      <button class="btn btn-show-cart" @click="showInMap">
        {{ $t("showMap") }}
      </button>
    </div>
    <div class="address-filters" :class="{ 'is-active': isShowFilter }">
      <button class="btn-back" @click.prevent="isShowFilter = false">
        {{ $t("back") }}
      </button>
      <div class="address-filters__filter">
        <span class="address-filters__filter-title">{{
          $t("expandedRange")
        }}</span>
        <div v-if="productCatalog" class="address-filters__filter-wrapper">
          <div
            v-for="catalog in productCatalog"
            :key="catalog.id"
            class="address-filters__item"
          >
            <input
              type="checkbox"
              :id="catalog.attributes.name + catalog.id"
              @change="changeFilter('features', catalog)"
              :checked="filters.features.includes(catalog.id)"
            />
            <label
              class="filter-label"
              :for="catalog.attributes.name + catalog.id"
              >{{ catalog.attributes.name }}</label
            >
          </div>
        </div>
      </div>
      <div class="address-filters__filter">
        <span class="address-filters__filter-title">{{ $t("services") }}</span>
        <div class="address-filters__filter-wrapper">
          <div
            v-for="service in services"
            :key="service.id"
            class="address-filters__item"
          >
            <input
              type="checkbox"
              :id="service.attributes.name + service.id"
              @change="changeFilter('features', service)"
              :checked="filters.features.includes(service.id)"
            />
            <label
              class="filter-label"
              :for="service.attributes.name + service.id"
              >{{ service.attributes.name }}</label
            >
          </div>
        </div>
      </div>
      <div v-if="shopTypesList" class="address-filters__filter">
        <span class="address-filters__filter-title">{{
          $t("storeFormat")
        }}</span>
        <div class="address-filters__filter-wrapper">
          <div
            v-for="type in shopTypesList"
            :key="type.id"
            class="address-filters__item"
          >
            <input
              type="checkbox"
              :id="type.attributes.name"
              @change="changeFilter('shopType', type)"
              :checked="filters.shopType.includes(type.id)"
            />
            <label class="filter-label" :for="type.attributes.name">{{
              type.attributes.name
            }}</label>
          </div>
        </div>
      </div>

      <div class="address-filters__btns">
        <button class="address-filters__btn-apply" @click="showFilteredShops">
          {{ $t("apply") }}
        </button>
        <button class="address-filters__btn-reset" @click="clearFilters()">
          {{ $t("filtersReset") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../helpers";
import { getShopFeatures } from "@/api/shop";

export default {
  name: "ShopsList",
  props: {
    shopsList: {
      type: Array,
      required: true,
    },
    shopTypesList: {
      type: Array,
    },
    isShowDetails: {
      type: Boolean,
      default: false,
    },
    isTermReset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchResults: null,
      selectedShop: null,
      term: "",
      isShowFilter: false,
      shopFeatures: null,
      filters: { shopType: [], features: [] },
    };
  },

  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    isShowList() {
      return this.term === "" ? false : true;
    },
    services() {
      return this.shopFeatures?.filter(
        (s) => s.attributes.type_of_feature === "service"
      );
    },
    productCatalog() {
      return this.shopFeatures?.filter(
        (s) => s.attributes.type_of_feature === "product_catalog"
      );
    },
  },
  watch: {
    isTermReset: {
      handler() {
        if (this.isTermReset) {
          this.term = "";
        }
      },
    },
    selectedCity: {
      deep: true,
      handler() {
        this.clearFilters();
      },
    },
  },
  created() {
    getShopFeatures().then((shopFeatures) => {
      this.shopFeatures = shopFeatures;
    });
  },
  methods: {
    showFilteredShops() {
      this.isShowFilter = false;
      this.$emit("closeDetails", false);

      this.$emit("filter-changed", this.filters);
    },

    clearFilters() {
      this.filters = { shopType: [], features: [] };
      this.isShowFilter = false;
      this.$emit("filter-changed", this.filters);
    },

    changeFilter(type, filter) {
      const foundIndex = this.filters[type].findIndex((f) => f === filter.id);
      if (foundIndex === -1) {
        this.filters[type].push(filter.id);
        return;
      }

      this.filters[type].splice(foundIndex, 1);
    },
    toggleAddressFilters() {
      this.isShowFilter = !this.isShowFilter;
    },
    showMoreInfo(shop) {
      this.selectedShop = shop;
      this.$emit("openDetails", true);
    },
    showInMap() {
      eventBus.$emit("gtm", {
        event: "shop_detail",
        type: "shop",
        name: this.selectedShop?.attributes.name,
        city: this.selectedCity.attributes.name,
      });
      this.$emit("show-in-map", this.selectedShop?.attributes.geo);
    },
    getShopWorkTime(shop) {
      const timeOptions = { hour: "2-digit", minute: "2-digit" };
      const openTime = new Date(
        "December 14, 2026 " + shop.attributes.open_hour
      ).toLocaleString("ru-RU", timeOptions);
      const closeTime = new Date(
        "December 14, 2026 " + shop.attributes.close_hour
      ).toLocaleString("ru-RU", timeOptions);

      return `${openTime} - ${closeTime}`;
    },
    getImage(feature) {
      const url = feature.attributes?.logo_dark?.data?.attributes?.url;
      if (url) {
        return this.$helpers.getAbsolutePath(url);
      }
      return "https://i.pinimg.com/originals/ef/19/63/ef1963550bd12b567e853a36ff1c5078.png";
    },
  },
};
</script>
